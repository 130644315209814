<template>
  <svg :width="svg_size" :height="svg_size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm-1.4 14.5L6 11.9l1.4-1.5 3.1 3.2 6-6.1L18 9l-7.4 7.5z" :fill="svg_color"/>
    </svg>
</template>

<script>

export default {
  name: 'CheckmarkCircleFilledSVG',

  data: () => ({
    svg_color: "currentColor",
    svg_size: 24
  }),

  props: { color: String, size: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
    if (this.size) { this.svg_size = this.size; }
  },

  computed: { },

  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
