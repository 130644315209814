export default {

    // Generic wrapper for accessing cloud functions
    // Accepts params and returns a fetch promise object
    // ======================================================= //

    async cloudFunction(app,params)
    {
        if(app.rootGetters['auth/isSignedIn'])
        {
            let apiRootURL = await app.getters.apiRootURL;

            let url = apiRootURL + (params.path || "") + "?"+new Date().getTime();
            let body = JSON.stringify(params.body);
            let method = params.method || "GET";
            let headers = app.rootGetters['auth/headers'];

            let response = await fetch(url, { method, headers, body });

            if (response.status == "401") {
                app.dispatch("auth/signOut",{},{root:true});
            } else  {
                let data = await response.json();
                return {
                    data: response.ok ? data : false,
                    error: response.ok ? false: data
                }
            }

        } else {
            app.dispatch("auth/signOut",{},{root:true});
            return false;
        }
    },

    // ======================================================= //
    // It all starts here...
    // ======================================================= //

    async init(app) {

        let gotConfigs = (app.state.translations) && (app.state.languages) && (app.state.configs);

        if (gotConfigs) { return; }

        app.commit('set',{loading: true});
        await app.dispatch('getConfigs');
        app.commit('set',{loading: false});
    },

    // ======================================================= //
    // Gets Application UI Translation Data and settings
    // from Google Cloud Storage Bucket
    // using Authorization Token
    // @param app - Instance of Application
    // ======================================================= //

    async getConfigs(app)
    {
        let gotConfigs = (app.state.translations) && (app.state.languages) && (app.state.configs);

        if (gotConfigs) { return; }

        let response = await fetch('cms_configs.json?'+new Date().getTime());
        let configs = await response.json();

        app.commit('set',{
            configs,
            lang: configs.language,
            country: configs.country
        });

        response = await app.dispatch("cloudFunction",{ path: "getconfigs" });

        if (!response.error)
        {
            let translations = response.data.translations || [];
            let languages = response.data.languages || [];
            app.commit('set',{ translations, languages });
        }
    },

    // ======================================================= //
    // Uploads Bundles Data to Google Cloud Storage Bucket
    // using id_token generated by Google Authentication
    // @param app - Instance of Application
    // ======================================================= //

    async toggleBundle(app, bundle_id)
    {
        let response = await app.dispatch("cloudFunction",
            { method: "PUT", path: "bundle/toggle/"+bundle_id });

        if (response.data) {
            app.commit('set',{ bundles: response.data });
            return response.data;
        } else {
            console.log({error: response.error});
        }
    },

    // ======================================================= //
    // Gets Bundle Data from Google Cloud Storage Bucket
    // using Authorization Token
    // ======================================================= //

    async getBundles(app)
    {
        app.commit('set',{loading: true});
        let response = await app.dispatch("cloudFunction",
            { path: "bundles" });

        if (response.error) {
            console.log({error: response.error});
            return [];
        } else {
            app.commit('set',{ bundles: response.data, loading: false });
            return response.data;
        }
    },

    // ======================================================= //
    // Saves changes to an existing bundle
    // or adds a new one if bundle.id = 0
    // Returns sparse bundles list as response array
    // ======================================================= //

    async saveBundle(app,bundle)
    {
        // bundle.id == 0 means new bundle
        app.commit('set',{loading: true});
        let response = await app.dispatch("cloudFunction",{
            path: "bundle",
            method: bundle.id ? "PUT" : "POST",
            body: bundle
        });

        if (response.data) {
            app.commit('set',{ bundles: response.data });
        }

        app.commit('set',{ loading: false });

        return response;
    },

    // ======================================================= //
    // Delete a bundle
    // Returns sparse bundles list as response array
    // ======================================================= //

    async deleteBundle(app,bundle)
    {
        // bundle.id == 0 means new bundle
        app.commit('set',{loading: true});
        let response = await app.dispatch("cloudFunction",{
            path: "bundle/"+bundle.id,
            method: "DELETE"
        });

        if (response.data) {
            app.commit('set',{ bundles: response.data });
        }

        app.commit('set',{ loading: false });
    },

    // ======================================================= //
    // Find a product article using the INGKA API
    // ======================================================= //

    async findArticle(app,articleId)
    {

      let siteLang = app.state.lang;
      let siteCountry = app.state.country;

      app.commit('set',{loading: true});

      let response = await app.dispatch("cloudFunction",{
        method: 'POST', path: "findArticle",
        body: { siteLang, siteCountry, articleId }
      });

      app.commit('set',{loading: false});

      return response;

    },

    // ======================================================= //
    // Load a specified bundle
    // ======================================================= //

    async loadBundle(app,bundle_id)
    {
        let langs = app.getters.langs;
        let details = app.state.bundleDetailsTemplate;

        if(bundle_id > 0)
        {
            app.commit('set',{loading: true});

            // Fetch data for this bundle
            let response = await app.dispatch("cloudFunction",{ path: "bundle/"+bundle_id });

            if (response.data)
            {
                let bundle = response.data;

                // Fill any missing bundle details with empty details
                bundle.details = langs.reduce((d,l) => {
                    if (d[l] === undefined) { d[l] = details; }
                    return d;
                },bundle.details);

                app.commit('set',{loading: false});
                return bundle;
            } else {
                console.log({error: response.error});
            }
        }
        else
        {
            // Construct empty bundle object
            let new_bundle = {
                active: 0,
                bundle_price: 0,
                country: app.state.country,
                details: langs.reduce((d,l) => { d[l] = details; return d; },{}),
                ga_event_label: "",
                id: 0,
                products: [],
                urls: [],
                inspo_image: "",
                inspo_images: [],
            };
            return new_bundle;
        }
    },
};
