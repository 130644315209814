// Function to set the value of a node using a dot-notation string as a key

const setObjectNode = function(obj,is, value) {
    if (typeof is == 'string')
        return setObjectNode(obj,is.split('.'), value);
    else if (is.length==1 && value!==undefined)
        return obj[is[0]] = value;
    else if (is.length==0)
        return obj;
    else
        return setObjectNode(obj[is[0]],is.slice(1), value);
};

export default
{
	  setObjectNode,

	  set(state, params)
    {
      for(let [key, v] of Object.entries(params)) {
        setObjectNode(state,key,v);
      }
	  },

  };

