export default
{
    headers(auth) {
        return {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'id_token': auth?.user?.auth?.id_token || false,
            'user': auth?.user?.profile?.email || false
        };
    },

    isSignedIn(auth) {

        let id_token = auth.user?.auth?.id_token || false;
        let expires_at = auth.user?.auth?.expires_at || 0;
        let time_left = expires_at - new Date().getTime();

        return id_token && time_left > 0;
    },

    idToken(auth) {
        return auth?.user?.auth?.id_token || false;
    }

};

