<template>

  <div class="form-field" :class="{'form-field--error': !valid && message, 'form-field--valid': valid && message }">
      <div class="select"
        :class="{'select--success': message && valid, 'select--leading-icon': !!icon, 'label-wrapper': label, 'label-wrapper--text-input': label}">
        <label for="example-id" class="" title="Label" v-if="label" v-html="label"></label>
          <div class="select__wrapper">

            <select id="example-id" aria-describedby="helper-msg-id" type="text" :value="value" @input="$emit('input',$event.target.value)">
              <option v-for="(option,o) in options"
                v-bind:key="o"
                :value="option.value"
                v-html="option.text"
                :selected="option.value == value"></option>
            </select>

            <component v-if="icon" :is="icon" class="svg-icon select__icon"></component>

            <svg focusable="false" viewBox="0 0 24 24" class="svg-icon select__chevron-down" aria-hidden="true">
                <path fill-rule="evenodd" clip-rule="evenodd" d="m12.0003 15.5996-5.7857-5.785 1.414-1.4143 4.3717 4.3711 4.3717-4.3711 1.4141 1.4143-5.7858 5.785z"></path>
            </svg>

            <span class="select__border"></span></div>
      </div>

      <div class="form-field__content">
        <span v-if="helper_message" id="helper-msg-id" aria-hidden="false" class="form-field__message-wrapper" type="">
          <span class="form-field__message">Field helper msg text</span>
        </span>
      </div>
  </div>

</template>

<script>

import "@ingka/svg-icon/style.scss";
import "@ingka/forms/style.scss";

export default {
  name: 'SkapaSelect',
  data: () => ({
  }),

  props: { value: String, options: Array, label: String, message: String, helper_message: String, icon: String, valid: Boolean},

  mounted() { },

  computed: { },

  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
