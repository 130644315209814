export default
{
    apiRootURL(app)
    {

        let projectId = app.configs.projectId;
        let function_region = app.configs.function_region;

        let port = window.location.port || false;

        // port = false; // Remove when local API server is working

        return(port
            ? "http://localhost:3000/"
            : "https://"+function_region+"-"+projectId+".cloudfunctions.net/inspo-bundler/");

    },

    bundleTemplate(app,getters) {

        let country = app.country;
        let langs = getters.langs;
        let detailsTemplate = app.bundleDetailsTemplate;

        return {
            active: 0,
            bundle_price: 0,
            country,
            details: langs.reduce((details,lang) => {
                details[lang] = detailsTemplate;
                return details;
            },{}),
            ga_event_label: "",
            id: 0,
            products: [],
            urls: []
        }
    },

    bundles(app) {
        let country = app.country;
        let bundles = app.bundles || [];
        return bundles.filter(b=> b.country == country);
    },

    // Return only languages for user's country
    languages(app) {
        let languages = app.languages || [];
        return languages.filter(l => l.country == app.country);
    },

    // Return abbreviated language codes for user's country
    langs(app){
        return app.languages.filter(l => l.country == app.country).map(l => l.lang);
    },

    translations(app)
    {
        let translations = app.translations || [];
        return translations.reduce((t, et) =>
        {
            if (et.country == app.country && et.lang == app.lang)
            {
                t[et.key] = et.text || et.key;
            }
            return t;
        }, {});
    },


};

