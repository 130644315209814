export default
{
    loading: false,
    modal: false,
    country: '',
    lang: '',
    translations: false,
    bundles: false,
    languages: [],

    bundleDetailsTemplate: {
        add_another_copy: "",
        add_button_copy: "",
        call_out_copy: "",
        lang: "",
        text: "",
        legal: "",
        title: "",
    },

    configs: false

};

