<template>
  <!-- <input v-bind:value="value" v-on:input="$emit('input', $event.target.value)"> -->

  <div class="form-field form-field--with-character-limit"
    :class="{'form-field--error': !valid, 'form-field--valid': valid, 'form-field--with-character-limit': true }">
		<div class="input-field label-wrapper label-wrapper--text-input">
			<label class="" :title="label" v-html="label"></label>

			<div class="input-field__wrapper">
			<input :type="type" :step="step" :id="id" aria-describedby="helper-msg-id" aria-required="false" aria-invalid="false" v-model="inputValue" >
			<span class="input-field__border"></span>
			</div>

			<div class="form-field__content">
				<span aria-hidden="false" class="form-field__message-wrapper" :type="valid?'valid':'error'" v-if="message">
					<span class="form-field__icon-wrapper">
					<svg v-if="valid" focusable="false" viewBox="0 0 24 24" class="svg-icon form-field__icon" aria-hidden="true">
						<path fill-rule="evenodd" clip-rule="evenodd" d="m11.0001 12.5859 4.2929-4.293 1.4142 1.4143-5.7071 5.7071-3.7071-3.7071 1.4142-1.4142L11 12.5859z"></path>
					</svg>
                    <svg v-else focusable="false" viewBox="0 0 24 24" class="svg-icon form-field__icon" aria-hidden="true">
						<path d="M12.9482 13.1025h-2.0035v-6h2l.0035 6zm-2.25 2.75c0-.6903.5597-1.25 1.25-1.25.6904 0 1.25.5597 1.25 1.25 0 .6904-.5596 1.25-1.25 1.25-.6903 0-1.25-.5596-1.25-1.25z"></path>
					</svg>
					</span>
					<span class="form-field__message" v-if="message" v-html="message"></span>
				</span>
				<span v-if="details" class="form-field__character-limit">
					<template v-for="(v,l) in validations" >
						<span
							style="cursor: pointer;"
							:key="l"
							:class="{bold: l==edit_lang, valid: v.valid, invalid: !v.valid }"
							@click="edit_lang=l" v-html="l"></span>
					</template>
				</span>
			</div>
		</div>
	</div>


</template>

<script>

export default
{
	name: 'SkapaInputLocalized',

	data: () => ({
		edit_details: {},
		edit_lang: ""
	}),

	props: ['type','id','step','label','details','input_key','validations'],

	mounted() {
		this.edit_lang = this.$store.state.app.lang;
		this.edit_details = this.details;
	},

    watch: {
		details(details) {
			this.edit_details = details;
		},
		user_lang(user_lang) {
			this.edit_lang = user_lang;
		}
	},

	computed: {

		message() {
			if (this.edit_lang && this.validations) {
				return this.validations[this.edit_lang].message;
			} else {
				return "";
			}
		},

		valid() {
			if (this.edit_lang && this.validations) {
				return this.validations[this.edit_lang].valid;
			} else {
				return true;
			}
		},

		user_lang() {
			return this.$store.state.app.lang;
		},

		inputValue: {
  			get()
  			{
	  			if (this.edit_lang && this.details) {
		  			return this.input_key
					  ? this.edit_details[this.edit_lang][this.input_key]
					  : this.edit_details[this.edit_lang];
	  			} else {
		  			return "";
	  			}
  			},

  			set(updated)
  			{
	  			if (this.edit_lang && this.details) {
					if (this.input_key) {
						this.edit_details[this.edit_lang][this.input_key] = updated;
					} else {
						this.edit_details[this.edit_lang] = updated;
					}
	  			}
  			}
		},

		langs() { return this.$store.getters['app/langs']; }
	},

	methods: {

	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.form-field__character-limit {
	display: flex;
	text-transform: uppercase;
	gap: 0.5em;
}

.bold {
	font-weight: bold;
}

.valid {
	// color: #0a8a00;
	color: #2c3e50;
}



.invalid { color: #e00751; }

</style>
