import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// Global
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
Vue.config.devtools = true;

// Skapa components
import '@/components/Skapa/';

import money from 'v-money'
// register directive v-money and component <money>
Vue.use(money, {precision: 4});

import './assets/styles/skapa.css';
import './assets/styles/animations.css';
import './assets/styles/custom.css';

import Gapi from 'vue-googleapis';

const gapiConfig = {
  clientId: '908876755856-ufv7ncn0lkncsqk5t0j1du79m4ptccrl.apps.googleusercontent.com',
  scope: [
    'https://www.googleapis.com/auth/devstorage.full_control',
    'https://www.googleapis.com/auth/cloud-platform',
    'https://www.googleapis.com/auth/cloud-identity.groups.readonly'
  ].join(" "),
};

Vue.use(Gapi, gapiConfig);

// Note: store uses Vue Gapi as dependency
import store from './store/store';

// LIGHTBOX
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import router from './router'

Vue.use(CoolLightBox);



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

