<template>

	<button type="button"
		class="btn"
		:class="{'btn--emphasised':emphasised, 'btn--small': small, 'btn--secondary': secondary, 'leading-icon': icon, 'btn--loading': loading }"
		@click="$emit('click')">
    	<span class="btn__inner" >
        <component v-if="svg[icon]" :is="svg[icon]" :color="loading?'none':svg_color"></component>
      	<span class="btn__label">{{ label }}</span>
        <span class="btn__loader">Loading…</span>
    	</span>
	</button>

</template>

<script>

export default {
  name: 'SkapaButton',
  data: () => ({

    svg_color: "currentColor",

    svg: {
      plus: "PlusSVG",
      cross: "CrossSVG",
      pencil: "PencilSVG",
      warning: "WarningSVG",
      checkmark: "CheckmarkSVG",
      magnifier: "MagnifierSVG",
      upload: "ArrowCloudInSVG",
      revert: "ArrowCounterclockwiseSVG",
      trash: "TrashCanSVG"
    }
  }),

  props: {
    label: String,
    icon: String,
    emphasised: Boolean,
    loading: Boolean,
    small: Boolean,
    secondary: Boolean
  },


  mounted() { },

  computed: { },

  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
button { background-color: white; }
</style>
