<template>

  <div class="form-field form-field--with-character-limit"
    :class="{'form-field--error': !valid, 'form-field--valid': valid }">
		<div class="input-field label-wrapper label-wrapper--text-input">
			<label class="" :title="label" v-html="label"></label>

			<div class="input-field__wrapper">
			<input :type="type" :step="step" :id="id" aria-describedby="helper-msg-id" aria-required="false" aria-invalid="false" v-bind:value="value" v-on:input="$emit('input', $event.target.value)">
			<span class="input-field__border"></span>
			</div>

			<div class="form-field__content">
				<span aria-hidden="false" class="form-field__message-wrapper" :type="valid?'valid':'error'" v-if="message">
					<span class="form-field__icon-wrapper">
					<svg v-if="valid" focusable="false" viewBox="0 0 24 24" class="svg-icon form-field__icon" aria-hidden="true">
						<path fill-rule="evenodd" clip-rule="evenodd" d="m11.0001 12.5859 4.2929-4.293 1.4142 1.4143-5.7071 5.7071-3.7071-3.7071 1.4142-1.4142L11 12.5859z"></path>
					</svg>
                    <svg v-else focusable="false" viewBox="0 0 24 24" class="svg-icon form-field__icon" aria-hidden="true">
						<path d="M12.9482 13.1025h-2.0035v-6h2l.0035 6zm-2.25 2.75c0-.6903.5597-1.25 1.25-1.25.6904 0 1.25.5597 1.25 1.25 0 .6904-.5596 1.25-1.25 1.25-.6903 0-1.25-.5596-1.25-1.25z"></path>
					</svg>
					</span>
					<span class="form-field__message" v-if="message" v-html="message"></span>
				</span>
			</div>
		</div>
	</div>


</template>

<script>

export default {
  name: 'SkapaInput',
  data: () => ({
  }),

  props: ['type','id','step','value','valid','message','label'],

  mounted() {
  },

  computed: {
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
