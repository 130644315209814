<template>

<div aria-hidden="false" class="inline-message" :class="type?'inline-message--'+type:''">

  <component v-if="svg[icon]" :is="svg[icon]" :color="svg_color"></component>

  <svg v-if="type=='cautionary'" focusable="false" viewBox="0 0 24 24" class="svg-icon inline-message__icon" aria-hidden="true">
      <path d="M11.0036 14.1337h2v-6h-2v6zm1 1.5c-.6904 0-1.25.5597-1.25 1.25 0 .6904.5596 1.25 1.25 1.25.6903 0 1.25-.5596 1.25-1.25 0-.6903-.5597-1.25-1.25-1.25z"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="m1.1152 20.418 10-19h1.7699l10 19-.8849 1.4657h-20l-.885-1.4657zm2.5413-.5343h16.6873L12.0002 4.0308 3.6565 19.8837z"></path>
  </svg>

  <svg v-if="type=='informative'" focusable="false" viewBox="0 0 24 24" class="svg-icon inline-message__icon" aria-hidden="true">
    <path d="M12 9.5c.6904 0 1.25-.5596 1.25-1.25S12.6904 7 12 7s-1.25.5596-1.25 1.25S11.3096 9.5 12 9.5zM11 11v6h2v-6h-2z"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10 5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10zM4 12c0-4.4183 3.5817-8 8-8s8 3.5817 8 8-3.5817 8-8 8-8-3.5817-8-8z"></path>
  </svg>

  <svg v-if="type=='negative'" focusable="false" viewBox="0 0 24 24" class="svg-icon inline-message__icon" aria-hidden="true">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 12c0 4.4183-3.5817 8-8 8-1.8487 0-3.551-.6271-4.9056-1.6801L18.3199 7.0944C19.3729 8.449 20 10.1513 20 12zM5.6801 16.9056 16.9056 5.6801C15.551 4.6271 13.8487 4 12 4c-4.4183 0-8 3.5817-8 8 0 1.8487.627 3.551 1.6801 4.9056zM22 12c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10C2 6.4771 6.4771 2 12 2c5.5228 0 10 4.4771 10 10z"></path>
  </svg>

  <svg v-if="type=='positive'" focusable="false" viewBox="0 0 24 24" class="svg-icon inline-message__icon" aria-hidden="true">
    <path d="M18 8.9608 16.5591 7.5l-5.9955 6.0784-3.1227-3.166L6 11.8732 10.5636 16.5 18 8.9608z"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22c5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10zm0-2c4.4183 0 8-3.5817 8-8s-3.5817-8-8-8-8 3.5817-8 8 3.5817 8 8 8z"></path>
  </svg>

  <div>
      <p class="inline-message__title" v-html="title"></p>
      <span class="inline-message__body">
        <span v-html="message"></span>
        <a v-if="link && link_text" :href="link" class="link" v-html="link_text"></a>
      </span>
  </div>

  <button type="button" class="btn btn--small btn--xsmall btn--icon-tertiary inline-message__close-button" v-if="dismissable" @click="$emit('dismiss');">
    <span class="btn__inner">
      <svg focusable="false" viewBox="0 0 24 24" class="svg-icon btn__icon" aria-hidden="true">
        <path fill-rule="evenodd" clip-rule="evenodd" d="m12.0006 13.4148 2.8283 2.8283 1.4142-1.4142-2.8283-2.8283 2.8283-2.8283-1.4142-1.4142-2.8283 2.8283L9.172 7.7578 7.7578 9.172l2.8286 2.8286-2.8286 2.8285 1.4142 1.4143 2.8286-2.8286z"></path>
      </svg>
    </span>
  </button>
</div>

</template>

<script>

import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import '@ingka/inline-message/dist/style.css';
import "@ingka/focus/dist/style.css";

export default {
  name: 'SkapaInputMessage',
  data: () => ({

    svg_color: "currentColor",

    svg: {
      plus: "PlusSVG",
      cross: "CrossSVG",
      pencil: "PencilSVG",
      warning: "WarningSVG",
      checkmark: "CheckmarkSVG",
      magnifier: "MagnifierSVG",
      upload: "ArrowCloudInSVG",
      revert: "ArrowCounterclockwiseSVG",
      trash: "TrashCanSVG"
    }
  }),

  props: {
    dismissable: Boolean,
    title: String,
    message: String,
    link: String,
    link_text: String,
    type: String, // informative, cautionary, negative, positive
    icon: String,
    valid: Boolean
  },

  mounted() { },

  computed: { },

  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.inline-message {
  padding-right: 0;
  margin-bottom: 1em;
  div { flex-grow: 1; }
}

</style>
