<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0022 7c2.7615 0 5 2.2386 5 5s-2.2385 5-5 5a4.989 4.989 0 0 1-1.7936-.3313l-1.5063 1.5062c.9832.5265 2.1067.8251 3.2999.8251 3.866 0 7-3.134 7-7s-3.134-7-7-7c-3.8403 0-6.9584 3.0926-6.9995 6.9233L3.417 10.3377 2.003 11.752l4.2427 4.2426 4.2426-4.2426-1.4142-1.4143-2.0713 2.0713v-.478C7.0397 9.2014 9.264 7 12.0022 7z" :fill="svg_color"/>
  </svg>

</template>

<script>

export default {
  name: 'ArrowCounterclockwiseSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
