<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 13H5v-2h14v2z" :fill="svg_color"/>
  </svg>

</template>

<script>

export default {
  name: 'MinusSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
