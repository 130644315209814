<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 9h2v8h-2V9zm-2 0H9v8h2V9z" :fill="svg_color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4892 5c-.0258-.577-.1265-1.2552-.5198-1.819C14.3653 2.315 13.3219 2 11.9981 2c-1.3531 0-2.4193.3063-3.0168 1.1953-.2761.4109-.3861.8584-.4359 1.2488-.0234.1833-.0352.3719-.0412.5559H3v2h2v14h14V7h2V5h-5.5108zm-4.9598-.303a3.551 3.551 0 0 0-.0239.303h2.9782c-.0229-.371-.0736-.5586-.1546-.6747C13.284 4.2607 13.0774 4 11.9981 4c-1.1451 0-1.3289.2693-1.3568.311-.0367.0545-.0833.161-.1119.386zM17 7H7v12h10V7z" :fill="svg_color"/>
  </svg>

</template>

<script>

export default {
  name: 'TrashCanSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
