<template>
  <svg :width="svg_size" :height="svg_size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C22,6.5,17.5,2,12,2z M11,7h2l0,6h-2V7z M12,17
	c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2C13.3,16.4,12.7,17,12,17z" :fill="svg_color"/>
    </svg>
</template>

<script>

export default {
  name: 'NoticeCircleFilledSVG',

  data: () => ({
    svg_color: "currentColor",
    svg_size: 24
  }),

  props: { color: String, size: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
    if (this.size) { this.svg_size = this.size; }
  },

  computed: { },

  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
