<template>
  <div class="signIn" v-if="!isSignedIn">
    <div class="loginButton" @click="$store.dispatch('auth/signIn')">
      <ArrowRightToBaseSVG color="#fff" size="32"></ArrowRightToBaseSVG>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginView',
  data: () => ({ }),

  computed: {
    loading() { return this.$store.state.app.loading; },
    isSignedIn() { return this.$store.getters['auth/isSignedIn']; }
  },

}
</script>


<style lang="scss" scoped>
.signIn {

  background-repeat: repeat;

  display: flex;
  align-items:center;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
}

.loginButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background: #0058a3;
  border-radius: 3.5rem;
  border: 1rem solid #fff;
  transform: scale(1);
  transition: transform 0.25s, background 0.25s;
  &:hover {
    background: #004f93;
    transform: scale(1.125);
  }
}

</style>
