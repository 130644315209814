<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0036 14.1337h2v-6h-2v6zm1 1.5c-.6904 0-1.25.5597-1.25 1.25 0 .6904.5596 1.25 1.25 1.25.6903 0 1.25-.5596 1.25-1.25 0-.6903-.5597-1.25-1.25-1.25z" :fill="svg_color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="m1.1152 20.418 10-19h1.7699l10 19-.8849 1.4657h-20l-.885-1.4657zm2.5413-.5343h16.6873L12.0002 4.0308 3.6565 19.8837z" :fill="svg_color"/>
  </svg>

</template>

<script>

export default {
  name: 'WarningSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
