<template>
  <header>
    <nav>
      <div style="flex-grow: 1;">
        <SkapaTabs :tabs="tabs" :tab.sync="tab"></SkapaTabs>
      </div>

      <div style="display: flex; align-items: center; padding-top: 0.5em;">
        <SkapaSelect icon="GlobeSVG" v-model="lang" :options="language_options"></SkapaSelect>
      </div>

      <div class="profile">
        <div>
          <h1><a href="#/" v-html="t.nav_inspirationBundler"></a></h1>
          <div class="user-name">
            <span v-html="userName"></span>
            <div @click="signOut" class="sign-out">
              <ArrowRightFromBaseSVG></ArrowRightFromBaseSVG>
            </div>
          </div>
        </div>
      </div>

    </nav>

  </header>
</template>

<script>
export default {

  name: "NavComponent",
  data() {
    return {
      nav_open: false,
    };
  },
  mounted() {},
  computed: {

    tab() { return this.$route.path.split("/")[1]; },
    t() { return this.$store.getters['app/translations']; },

    tabs() {

      let t = this.t;

      return [
        {
          tab: "bundles",
          route: "/bundles",
          title: t.nav_bundles,
        },
        {
          tab: "about",
          route: "/about",
          title: t.nav_about,
        },
        {
          tab: "translations",
          route: "/translations",
          title: t.nav_translations,
        },
      ];
    },

    userName() {
      let user = this.$store.state.auth.user;
      return user?.profile?.name || "";
    },

    lang: {
      get() { return this.$store.state.app.lang; },
      set(lang) { this.$store.commit("app/set", { lang }); },
    },

    country: {
      get() { return this.$store.state.app.country; },
      set(country) { this.$store.commit("app/set", { country }); },
    },

    language_options() {
      return this.$store.getters['app/languages'].map(l => {
        return { text: l.text, value: l.lang };
      });
    },

    isSignedIn() { return this.$store.getters['auth/isSignedIn']; },
  },

  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

nav {
  width: 100%;
  display: flex;
  margin: 0.5rem 0;
  gap: 1em;

  .profile {
    margin-right: 1rem;
    h1 {
      text-align: left;
      a {
          color: #003e72;
          text-decoration: none;
      }
    }

    .user-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sign-out {
      cursor: pointer;
    }

  }
}

</style>
