<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22H3V2h9v2H5v16h7v2z" :fill="svg_color"/>
    <path d="m16.1715 11-3.2429-3.243 1.4142-1.4142 5.6568 5.6568-5.6568 5.6569-1.4142-1.4142L16.1708 13H7.9999v-2h8.1716z" :fill="svg_color"/>
  </svg>

</template>

<script>

export default {
  name: 'ArrowRightFromBaseSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
