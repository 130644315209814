<template>

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="m15.5353 9.879-5.6568 5.6568-1.4142-1.4142 5.6568-5.6568 1.4142 1.4142z" :fill="svg_color"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9496 7.0506c-1.1716-1.1716-3.0711-1.1716-4.2427 0l-1.4142 1.4142-1.4142-1.4143 1.4142-1.4142c1.9526-1.9526 5.1185-1.9526 7.0711 0 1.9526 1.9527 1.9526 5.1185 0 7.0711l-1.4142 1.4142-1.4143-1.4142 1.4143-1.4142c1.1715-1.1716 1.1715-3.071 0-4.2427zM7.05 16.95c1.1716 1.1716 3.0711 1.1716 4.2427 0l1.4142-1.4142 1.4142 1.4142-1.4142 1.4143c-1.9526 1.9526-5.1184 1.9526-7.071 0-1.9527-1.9527-1.9527-5.1185 0-7.0711L7.05 9.879l1.4142 1.4142L7.05 12.7074c-1.1716 1.1716-1.1716 3.0711 0 4.2426z" :fill="svg_color"/>
</svg>

</template>

<script>

export default {
  name: 'AlertSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
