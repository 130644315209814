

export default
{
    isSignedIn: false,
    authToken: false,
    idToken: false,
    user: JSON.parse(window.sessionStorage.getItem("user")),
};

