<template>

	<div class="switch">
		<input type="checkbox" class="switch__checkbox" @change="changed" :checked="value" :disabled="disabled">
		<span class="switch__toggle">
			<span class="switch__button"><span class="switch__symbol"></span></span>
		</span>
		<label class="switch__label" v-if="label">{{ label }}</label>
	</div>

</template>

<script>

export default {
  name: 'SkapaSwitch',
  data: () => ({
  }),

  props: ['value','label','disabled'],

  mounted() {
  },

  computed: {
  },

  methods: {
	  changed(e) {
		  this.$emit('input', e.target.checked);
	  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
