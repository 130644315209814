<template>
  <main id="app" :class="{modal: modal}">
    <Nav v-if="isSignedIn"></Nav>
    <LoginView v-if="!loading && !isSignedIn"></LoginView>
    <router-view v-if="isSignedIn"></router-view>
    <Loader v-if="['/','/about','/bundles'].includes($route.path)"></Loader>
  </main>
</template>

<script>

import Nav from './components/Nav.vue'
import Loader from './components/Loader.vue'
import LoginView from '@/views/LoginView.vue'

export default {
  name: 'App',
  components: { Nav, Loader, LoginView },

  data: () => ({
  }),

  computed: {
    modal() { return this.$store.state.app.modal; },
    loading() { return this.$store.state.app.loading; },
    isSignedIn() { return this.$store.getters['auth/isSignedIn']; }
  },

}
</script>


<style lang="scss">

@keyframes openmodal {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

@keyframes modalbg {
  from { background-color: rgba(0, 0, 0, 0); }
  to { background-color: rgba(0, 0, 0, 0.5); }
}

.modal-container {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  	animation-name: modalbg;
  	animation-duration: 0.25s;

    .modal {
        animation-name: openmodal;
        animation-duration: 0.25s;

        padding: 1em;
        background-color: #fff;
        margin: 1em;
        border-radius: 0.75em;
        max-height: calc(100vh - 4em);
        display: flex;
        flex-direction: column;
        width: calc(100% - 4em);
        max-width: 60rem;

        overflow-y: hidden;

        @media only screen and (max-width: 639px) {
            margin: 0rem;
        }
    }
}

#app > div {
  width: 100%;
  max-width: 60rem;
  align-self: center;
}

#app {
  overflow-y: scroll;

  font-family: NotoSans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  display: flex;
  /* align-items:center; */
   height: 100vh;
  /* justify-content: center; */
   flex-direction: column;
}
</style>
