import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '../views/LoginView.vue';

import store from '@/store/store.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/translations',
    name: 'translations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "translations" */ '../views/TranslationsView.vue')
  },
  {
    path: '/bundles',
    name: 'bundles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bundles" */ '../views/BundlesView.vue')
  },
  {
    path: '/bundles/edit/:id/:tab?',
    name: 'edit_bundle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Bundle/EditBundleView.vue')
  },
]

const router = new VueRouter({routes})




router.beforeEach(async(to, from, next) =>
{
  if(store.getters["auth/isSignedIn"])
  {
    // Stall until app is initialized (configs etc. loaded)
    store.dispatch("auth/watchToken");
    await store.dispatch('app/init');
  }

  // Only allow access to home (login) page unless signed in
  if (to.name == "home" || store.getters['auth/isSignedIn']) {
    next();
  } else {
    next("/");
  }
});

export default router
