import { render, staticRenderFns } from "./CheckmarkSVG.vue?vue&type=template&id=4b36304b&scoped=true&"
import script from "./CheckmarkSVG.vue?vue&type=script&lang=js&"
export * from "./CheckmarkSVG.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b36304b",
  null
  
)

export default component.exports