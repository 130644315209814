<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17 18.0901v-2.0005c1.6651-.0481 3-1.4129 3-3.0896 0-1.5392-1.1261-2.8174-2.5993-3.0522l-.6745-.1076-.1452-.6673C16.0393 6.6839 14.1197 5 12 5 9.8803 5 7.9607 6.6839 7.419 9.1729l-.1452.6673-.6745.1076C5.1261 10.1826 4 11.4608 4 13c0 1.6767 1.335 3.0415 3 3.0896v2.0005C4.2303 18.0416 2 15.7813 2 13c0-2.3032 1.5287-4.2479 3.627-4.877C6.5066 5.2151 8.964 3 12 3c3.0361 0 5.4933 2.2152 6.3731 5.123C20.4713 8.752 22 10.6967 22 13c0 2.7813-2.2303 5.0416-5 5.0901z" :fill="svg_color"/>
  <path d="m11 12.8284-1.8285 1.8285-1.4142-1.4142L12 9l4.2426 4.2427-1.4142 1.4142L13 12.8285V21h-2v-8.1716z" :fill="svg_color"/>
</svg>

</template>

<script>

export default {
  name: 'ArrowCloudInSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

  watch: {
    color(color) { this.svg_color = color; }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
