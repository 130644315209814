<template>
  <div class="loader">
    <div v-if="loading" style="margin-top: 2em;">
        <span class="loading loading--label-center" aria-live="polite">
          <span class="loading__ball loading__ball--large loading__ball--emphasised" aria-hidden="true"></span>
          <span aria-hidden="true" class="loading__text loading__text--fade-in">Loading</span>
          <span class="loading__sr-only-text">Loading</span>
          </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent',
  data: () => ({}),
  computed: {
    loading() { return this.$store.state.app.loading; },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
