<template>

<svg :width="svg_size" :height="svg_size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 4V2h9v20h-9v-2h7V4h-7z" :fill="svg_color"/>
  <path d="M12.1711 11 8.9286 7.7575l1.4142-1.4142L16 12l-5.6572 5.657-1.4142-1.4142L12.1713 13H4v-2h8.1711z" :fill="svg_color"/>
</svg>

</template>

<script>

export default {
  name: 'ArrowRightToBase',

  data: () => ({
    svg_color: "currentColor",
    svg_size: 24
  }),

  props: { color: String, size: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
    if (this.size) { this.svg_size = this.size; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
