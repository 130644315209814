import { render, staticRenderFns } from "./SkapaInlineMessage.vue?vue&type=template&id=026db0dc&scoped=true&"
import script from "./SkapaInlineMessage.vue?vue&type=script&lang=js&"
export * from "./SkapaInlineMessage.vue?vue&type=script&lang=js&"
import style0 from "./SkapaInlineMessage.vue?vue&type=style&index=0&id=026db0dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026db0dc",
  null
  
)

export default component.exports