<template>

    <div class="tabs">
        <div role="tablist" class="tabs__list">

            <div class="overflow-carousel">

                <div class="overflow-carousel__content">
                    <template v-for="(t,index) in tabs">
                        <a v-if="!t.route||false"
                          class="tabs__tab" :class="{'tabs__tab--active': t.tab == tab, invalid: t.valid===false}"
                          role="tab"
                          tabindex="-1"
                          :aria-selected="t.tab == tab"
                          :aria-controls="'tab_'+index"
                          :key="'tab_'+index"
                          @click="clickTab(t)">
                            <span v-html="t.title"></span>
                            <span v-if="t.count !== undefined">&nbsp;({{t.count}})</span>
                        </a>
                        <router-link v-if="t.route"
                          :to="t.route"
                          class="tabs__tab" :class="{'tabs__tab--active': t.tab == tab, invalid: t.valid===false}"
                          role="tab"
                          tabindex="-1"
                          :aria-selected="t.tab == tab"
                          :aria-controls="'tab_'+index"
                          :key="'tab_'+index"
                          @click="clickTab(t)">
                            <span v-html="t.title"></span>
                            <span v-if="t.count !== undefined">&nbsp;({{t.count}})</span>
                        </router-link>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "@ingka/focus/dist/style.css";

export default {
  name: 'SkapaTabs',
  props: ['tab','tabs'],
  methods: {
    clickTab(t) {
      this.$emit('update:tab',t.tab);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  div.tabs { margin: 0 1em 1em 1em; }
  [role=tab] { cursor: pointer; }

.tabs__list::after {
    border-bottom: none;
}

.invalid { color: #e00751; }

</style>
