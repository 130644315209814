<template>

  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5591 7.5 18 8.9608 10.5636 16.5 6 11.8732l1.4409-1.4608 3.1227 3.166L16.5591 7.5z" :fill="svg_color"/>
  </svg>


</template>

<script>

export default {
  name: 'CheckmarkSVG',

  data: () => ({ svg_color: "currentColor" }),

  props: { color: String },

  mounted() {
    if (this.color) { this.svg_color = this.color; }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
