import Vue from 'vue';
import router from '@/router';

export default
{
    // Check the user's token once every second
    // to see if it needs to be refreshed

    watchToken(auth)
    {
        let expires_at = auth.state.user?.auth?.expires_at || 0;

        const tokenInterval = setInterval(()=>
        {
            let seconds_left = Math.max(0,Math.floor((expires_at - new Date().getTime())/1000));
            let minutes_left = Math.floor(seconds_left/60);

            if (seconds_left < 2) {
                console.log("User token expired.");
                clearInterval(tokenInterval);
                auth.dispatch('signOut');

            } else {
                if ((seconds_left % 600) == 0) {
                    console.log(minutes_left+" minutes until user token expires.");
                }
            }
        },1000);
    },

    startListener(auth) {
        let google = Vue.google;
        const interval = setInterval(() => {
            if (google.isInit) {
                google.api.auth2.getAuthInstance().currentUser.listen((response)=> {

                    let bp = response.getBasicProfile();

                    let user = {
                        profile: {
                            id: bp.getId(),
                            email: bp.getEmail(),
                            name: bp.getName(),
                            given_name: bp.getGivenName(),
                            family_name: bp.getFamilyName(),
                        },
                        auth: response.getAuthResponse()
                    };

                    auth.commit('set',{ user });
                    auth.commit('app/set',{loading: false},{root:true});
                    auth.dispatch('app/init',null,{root:true});
                    auth.dispatch('watchToken');

                    router.push('/bundles/').catch(()=>{});

                });
                clearInterval(interval);

            }
        }, 200);
    },

    signIn(auth) {

        auth.commit('app/set',{loading: true},{root:true});

        let google = Vue.google;

        auth.dispatch('startListener');

        try {
            google.api.auth2.getAuthInstance().signIn()
        } catch (e) {
            // console.log(e)
        }

    },


    signOut(auth) {

        let google = Vue.google;

        try {
            google.api.auth2.getAuthInstance().signOut;

            auth.commit("set", { isSignedIn: false, user: {} });
            auth.commit('app/set',{modal: false},{root:true});

            router.push("/").catch(() => {});
        } catch(e) {
            // console log({e});
        }
    },


    async getAuthToken(auth)
    {
        let google = Vue.google;
        let token = await google.api.client;
        token = token.getToken();
        auth.commit('set',{authToken: token.access_token});
    },


};
